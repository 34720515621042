'use strict';

var cov_2doyf8fqot = function () {
  var path = '/home/runner/work/miroslawrestel-portfolio/miroslawrestel-portfolio/src/database.js',
      hash = '49aa2c02709349e2ffca6f51df11d2b8278c4c8e',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/runner/work/miroslawrestel-portfolio/miroslawrestel-portfolio/src/database.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 14,
          column: 5
        }
      }
    },
    fnMap: {},
    branchMap: {},
    s: {
      '0': 0
    },
    f: {},
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2doyf8fqot.s[0]++;
angular.module('app').constant('DATABASE', {
  aboutLinkedin: 'linkedin.com/in/miroslawrestel',
  aboutMail: 'miroslaw.restel@gmail.com',
  aboutPhone: '+48 662 556 602',
  carouselItems: [{
    src: 'img/portfolio-items/(2) INTERIOR VISUALIZATION/2048/(1) interior-visualization-3d-living-room.jpg'
  }, {
    src: 'img/portfolio-items/(4) ILLUSTRATIONS/2048/(1) 3d-DNA-modell-visualization.jpg'
  }, {
    src: 'img/portfolio-items/(1) ARCHITECTURAL VISUALIZATION/2048/(1) architectural-visualization-3d-house-view.jpg'
  }]
});
'use strict';

var cov_r0fv3bez1 = function () {
  var path = '/home/runner/work/miroslawrestel-portfolio/miroslawrestel-portfolio/src/index.js',
      hash = 'c22cd7207a2c1a077bea16dc490e7d863138b8c6',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/runner/work/miroslawrestel-portfolio/miroslawrestel-portfolio/src/index.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 2,
          column: 65
        }
      }
    },
    fnMap: {},
    branchMap: {},
    s: {
      '0': 0
    },
    f: {},
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_r0fv3bez1.s[0]++;
angular.module('app', ['ngScrollbars', 'ngAnimate', 'slickCarousel']);
'use strict';

var cov_1strt0k1oc = function () {
  var path = '/home/runner/work/miroslawrestel-portfolio/miroslawrestel-portfolio/src/app/components/customModal/customModal.js',
      hash = '6f3109b1e3ec001c4568a3cfcee4fa34f4ec5809',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/runner/work/miroslawrestel-portfolio/miroslawrestel-portfolio/src/app/components/customModal/customModal.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 11,
          column: 5
        }
      },
      '1': {
        start: {
          line: 14,
          column: 13
        },
        end: {
          line: 14,
          column: 17
        }
      },
      '2': {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 31
        }
      },
      '3': {
        start: {
          line: 17,
          column: 2
        },
        end: {
          line: 19,
          column: 4
        }
      },
      '4': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 18,
          column: 23
        }
      },
      '5': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 28,
          column: 5
        }
      },
      '6': {
        start: {
          line: 23,
          column: 6
        },
        end: {
          line: 23,
          column: 35
        }
      },
      '7': {
        start: {
          line: 24,
          column: 11
        },
        end: {
          line: 28,
          column: 5
        }
      },
      '8': {
        start: {
          line: 25,
          column: 6
        },
        end: {
          line: 25,
          column: 34
        }
      },
      '9': {
        start: {
          line: 26,
          column: 11
        },
        end: {
          line: 28,
          column: 5
        }
      },
      '10': {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 27,
          column: 25
        }
      }
    },
    fnMap: {
      '0': {
        name: 'customModalController',
        decl: {
          start: {
            line: 13,
            column: 9
          },
          end: {
            line: 13,
            column: 30
          }
        },
        loc: {
          start: {
            line: 13,
            column: 33
          },
          end: {
            line: 30,
            column: 1
          }
        },
        line: 13
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 17,
            column: 15
          },
          end: {
            line: 17,
            column: 16
          }
        },
        loc: {
          start: {
            line: 17,
            column: 27
          },
          end: {
            line: 19,
            column: 3
          }
        },
        line: 17
      },
      '2': {
        name: 'changeSlide',
        decl: {
          start: {
            line: 21,
            column: 11
          },
          end: {
            line: 21,
            column: 22
          }
        },
        loc: {
          start: {
            line: 21,
            column: 44
          },
          end: {
            line: 29,
            column: 3
          }
        },
        line: 21
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 22,
            column: 4
          },
          end: {
            line: 28,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 22,
            column: 4
          },
          end: {
            line: 28,
            column: 5
          }
        }, {
          start: {
            line: 22,
            column: 4
          },
          end: {
            line: 28,
            column: 5
          }
        }],
        line: 22
      },
      '1': {
        loc: {
          start: {
            line: 22,
            column: 8
          },
          end: {
            line: 22,
            column: 48
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 22,
            column: 8
          },
          end: {
            line: 22,
            column: 28
          }
        }, {
          start: {
            line: 22,
            column: 32
          },
          end: {
            line: 22,
            column: 48
          }
        }],
        line: 22
      },
      '2': {
        loc: {
          start: {
            line: 24,
            column: 11
          },
          end: {
            line: 28,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 24,
            column: 11
          },
          end: {
            line: 28,
            column: 5
          }
        }, {
          start: {
            line: 24,
            column: 11
          },
          end: {
            line: 28,
            column: 5
          }
        }],
        line: 24
      },
      '3': {
        loc: {
          start: {
            line: 26,
            column: 11
          },
          end: {
            line: 28,
            column: 5
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 26,
            column: 11
          },
          end: {
            line: 28,
            column: 5
          }
        }, {
          start: {
            line: 26,
            column: 11
          },
          end: {
            line: 28,
            column: 5
          }
        }],
        line: 26
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_1strt0k1oc.s[0]++;
angular.module('app').component('customModal', {
  templateUrl: 'app/components/customModal/customModal.html',
  bindings: {
    item: '=',
    active: '='
  },
  controller: customModalController

});

function customModalController() {
  cov_1strt0k1oc.f[0]++;

  var vm = (cov_1strt0k1oc.s[1]++, this);
  cov_1strt0k1oc.s[2]++;
  vm.changeSlide = changeSlide;

  cov_1strt0k1oc.s[3]++;
  vm.$onInit = function () {
    cov_1strt0k1oc.f[1]++;
    cov_1strt0k1oc.s[4]++;

    vm.activeSlide = 0;
  };

  function changeSlide(slideNumber, length) {
    cov_1strt0k1oc.f[2]++;
    cov_1strt0k1oc.s[5]++;

    if ((cov_1strt0k1oc.b[1][0]++, slideNumber < length) && (cov_1strt0k1oc.b[1][1]++, slideNumber >= 0)) {
      cov_1strt0k1oc.b[0][0]++;
      cov_1strt0k1oc.s[6]++;

      vm.activeSlide = slideNumber;
    } else {
        cov_1strt0k1oc.b[0][1]++;
        cov_1strt0k1oc.s[7]++;
        if (slideNumber >= length) {
          cov_1strt0k1oc.b[2][0]++;
          cov_1strt0k1oc.s[8]++;

          vm.activeSlide = length - 1;
        } else {
            cov_1strt0k1oc.b[2][1]++;
            cov_1strt0k1oc.s[9]++;
            if (slideNumber < 0) {
              cov_1strt0k1oc.b[3][0]++;
              cov_1strt0k1oc.s[10]++;

              vm.activeSlide = 0;
            } else {
              cov_1strt0k1oc.b[3][1]++;
            }
          }
      }
  }
}
'use strict';

var cov_2crlqcknam = function () {
  var path = '/home/runner/work/miroslawrestel-portfolio/miroslawrestel-portfolio/src/const.js',
      hash = 'eb300d588f65f9fbc1c9136da7d5d56dbbde476a',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/runner/work/miroslawrestel-portfolio/miroslawrestel-portfolio/src/const.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 11,
          column: 5
        }
      }
    },
    fnMap: {},
    branchMap: {},
    s: {
      '0': 0
    },
    f: {},
    b: {},
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_2crlqcknam.s[0]++;
angular.module('app').constant('MODAL_SCROLL_CONFIG', {
  autoHideScrollbar: false,
  theme: 'light-3',
  advanced: {
    updateOnContentResize: true
  },
  scrollInertia: 300,
  axis: 'y'
});
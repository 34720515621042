'use strict';

var cov_1mwjklom3v = function () {
  var path = '/home/runner/work/miroslawrestel-portfolio/miroslawrestel-portfolio/src/app/main.js',
      hash = 'c6fdb0f6cfc2c6f14d09d6d50ba922f9b3c638b5',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/home/runner/work/miroslawrestel-portfolio/miroslawrestel-portfolio/src/app/main.js',
    statementMap: {
      '0': {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 6,
          column: 5
        }
      },
      '1': {
        start: {
          line: 9,
          column: 13
        },
        end: {
          line: 9,
          column: 17
        }
      },
      '2': {
        start: {
          line: 11,
          column: 2
        },
        end: {
          line: 11,
          column: 53
        }
      },
      '3': {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 49
        }
      },
      '4': {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 9
        }
      },
      '5': {
        start: {
          line: 16,
          column: 21
        },
        end: {
          line: 16,
          column: 29
        }
      },
      '6': {
        start: {
          line: 17,
          column: 4
        },
        end: {
          line: 17,
          column: 46
        }
      },
      '7': {
        start: {
          line: 18,
          column: 4
        },
        end: {
          line: 18,
          column: 46
        }
      },
      '8': {
        start: {
          line: 19,
          column: 4
        },
        end: {
          line: 19,
          column: 38
        }
      },
      '9': {
        start: {
          line: 20,
          column: 4
        },
        end: {
          line: 20,
          column: 46
        }
      },
      '10': {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 21,
          column: 38
        }
      },
      '11': {
        start: {
          line: 22,
          column: 4
        },
        end: {
          line: 22,
          column: 40
        }
      },
      '12': {
        start: {
          line: 23,
          column: 4
        },
        end: {
          line: 23,
          column: 40
        }
      },
      '13': {
        start: {
          line: 24,
          column: 4
        },
        end: {
          line: 24,
          column: 91
        }
      },
      '14': {
        start: {
          line: 24,
          column: 78
        },
        end: {
          line: 24,
          column: 87
        }
      },
      '15': {
        start: {
          line: 26,
          column: 4
        },
        end: {
          line: 26,
          column: 20
        }
      },
      '16': {
        start: {
          line: 30,
          column: 14
        },
        end: {
          line: 30,
          column: 16
        }
      },
      '17': {
        start: {
          line: 31,
          column: 32
        },
        end: {
          line: 31,
          column: 55
        }
      },
      '18': {
        start: {
          line: 33,
          column: 18
        },
        end: {
          line: 33,
          column: 19
        }
      },
      '19': {
        start: {
          line: 34,
          column: 12
        },
        end: {
          line: 34,
          column: 13
        }
      },
      '20': {
        start: {
          line: 37,
          column: 6
        },
        end: {
          line: 37,
          column: 16
        }
      },
      '21': {
        start: {
          line: 38,
          column: 6
        },
        end: {
          line: 41,
          column: 7
        }
      },
      '22': {
        start: {
          line: 39,
          column: 8
        },
        end: {
          line: 39,
          column: 31
        }
      },
      '23': {
        start: {
          line: 40,
          column: 8
        },
        end: {
          line: 40,
          column: 24
        }
      },
      '24': {
        start: {
          line: 44,
          column: 4
        },
        end: {
          line: 49,
          column: 5
        }
      },
      '25': {
        start: {
          line: 45,
          column: 6
        },
        end: {
          line: 45,
          column: 25
        }
      },
      '26': {
        start: {
          line: 46,
          column: 6
        },
        end: {
          line: 46,
          column: 35
        }
      },
      '27': {
        start: {
          line: 47,
          column: 6
        },
        end: {
          line: 47,
          column: 36
        }
      },
      '28': {
        start: {
          line: 48,
          column: 6
        },
        end: {
          line: 48,
          column: 41
        }
      },
      '29': {
        start: {
          line: 60,
          column: 4
        },
        end: {
          line: 60,
          column: 78
        }
      }
    },
    fnMap: {
      '0': {
        name: 'appCtrl',
        decl: {
          start: {
            line: 8,
            column: 9
          },
          end: {
            line: 8,
            column: 16
          }
        },
        loc: {
          start: {
            line: 8,
            column: 80
          },
          end: {
            line: 62,
            column: 1
          }
        },
        line: 8
      },
      '1': {
        name: 'init',
        decl: {
          start: {
            line: 15,
            column: 11
          },
          end: {
            line: 15,
            column: 15
          }
        },
        loc: {
          start: {
            line: 15,
            column: 18
          },
          end: {
            line: 27,
            column: 3
          }
        },
        line: 15
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 24,
            column: 70
          },
          end: {
            line: 24,
            column: 71
          }
        },
        loc: {
          start: {
            line: 24,
            column: 78
          },
          end: {
            line: 24,
            column: 87
          }
        },
        line: 24
      },
      '3': {
        name: 'preloadImages',
        decl: {
          start: {
            line: 29,
            column: 11
          },
          end: {
            line: 29,
            column: 24
          }
        },
        loc: {
          start: {
            line: 29,
            column: 27
          },
          end: {
            line: 57,
            column: 3
          }
        },
        line: 29
      },
      '4': {
        name: 'onloadFunction',
        decl: {
          start: {
            line: 36,
            column: 13
          },
          end: {
            line: 36,
            column: 27
          }
        },
        loc: {
          start: {
            line: 36,
            column: 30
          },
          end: {
            line: 42,
            column: 5
          }
        },
        line: 36
      },
      '5': {
        name: 'getPortfolioItemData',
        decl: {
          start: {
            line: 59,
            column: 11
          },
          end: {
            line: 59,
            column: 31
          }
        },
        loc: {
          start: {
            line: 59,
            column: 39
          },
          end: {
            line: 61,
            column: 3
          }
        },
        line: 59
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 38,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 38,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        }, {
          start: {
            line: 38,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        }],
        line: 38
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0
    },
    b: {
      '0': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

cov_1mwjklom3v.s[0]++;
angular.module('app').component('app', {
  templateUrl: 'app/main.html',
  controller: appCtrl
});

function appCtrl($scope, $http, MODAL_SCROLL_CONFIG, DATABASE, PORTFOLIO_ITEMS) {
  cov_1mwjklom3v.f[0]++;

  var vm = (cov_1mwjklom3v.s[1]++, this);

  cov_1mwjklom3v.s[2]++;
  vm.jsModalImagesScrollConfig = MODAL_SCROLL_CONFIG;
  cov_1mwjklom3v.s[3]++;
  vm.getPortfolioItemData = getPortfolioItemData;
  cov_1mwjklom3v.s[4]++;
  init();

  function init() {
    cov_1mwjklom3v.f[1]++;

    var response = (cov_1mwjklom3v.s[5]++, DATABASE);
    cov_1mwjklom3v.s[6]++;
    vm.aboutImageSrc = response.aboutImageSrc;
    cov_1mwjklom3v.s[7]++;
    vm.carouselItems = response.carouselItems;
    cov_1mwjklom3v.s[8]++;
    vm.aboutMail = response.aboutMail;
    cov_1mwjklom3v.s[9]++;
    vm.aboutLinkedin = response.aboutLinkedin;
    cov_1mwjklom3v.s[10]++;
    vm.aboutText = response.aboutText;
    cov_1mwjklom3v.s[11]++;
    vm.aboutPhone = response.aboutPhone;
    cov_1mwjklom3v.s[12]++;
    vm.portfolioItems = PORTFOLIO_ITEMS;
    cov_1mwjklom3v.s[13]++;
    vm.availablePortfolioItemsTypes = [].concat(_toConsumableArray(new Set(PORTFOLIO_ITEMS.map(function (each) {
      cov_1mwjklom3v.f[2]++;
      cov_1mwjklom3v.s[14]++;
      return each.type;
    }))));

    cov_1mwjklom3v.s[15]++;
    preloadImages();
  }

  function preloadImages() {
    cov_1mwjklom3v.f[3]++;

    var c = (cov_1mwjklom3v.s[16]++, []);
    var carouselItemsLength = (cov_1mwjklom3v.s[17]++, vm.carouselItems.length);
    // const portfolioItemsLength = vm.portfolioItems.length + carouselItemsLength;
    var counter = (cov_1mwjklom3v.s[18]++, 0);
    var i = (cov_1mwjklom3v.s[19]++, 0);

    function onloadFunction() {
      cov_1mwjklom3v.f[4]++;
      cov_1mwjklom3v.s[20]++;

      counter++;
      cov_1mwjklom3v.s[21]++;
      if (counter === carouselItemsLength) {
        cov_1mwjklom3v.b[0][0]++;
        cov_1mwjklom3v.s[22]++;

        vm.imagesLoaded = true;
        cov_1mwjklom3v.s[23]++;
        $scope.$apply();
      } else {
        cov_1mwjklom3v.b[0][1]++;
      }
    }

    cov_1mwjklom3v.s[24]++;
    for (i = 0; i < carouselItemsLength; i++) {
      cov_1mwjklom3v.s[25]++;

      c[i] = new Image();
      cov_1mwjklom3v.s[26]++;
      c[i].onload = onloadFunction;
      cov_1mwjklom3v.s[27]++;
      c[i].onerror = onloadFunction;
      cov_1mwjklom3v.s[28]++;
      c[i].src = vm.carouselItems[i].src;
    }

    // for (i; i < portfolioItemsLength; i++) {
    //   c[i] = new Image();
    //   c[i].onload = onloadFunction;
    //   c[i].onerror = onloadFunction;
    //   c[i].src = vm.portfolioItems[i - carouselItemsLength].portfolioItemImage;
    // }
  }

  function getPortfolioItemData(event) {
    cov_1mwjklom3v.f[5]++;
    cov_1mwjklom3v.s[29]++;

    return vm.portfolioItems[event.currentTarget.attributes['data-id'].value];
  }
}